<template>
  <div class="building-form">
    <v-form ref="form" class="building-form__form" @submit.prevent="submit">
      <v-row justify="start">
        <v-col cols="12" md="4">
          <h3 class="pb-5 primary--text">{{ header }}</h3>

          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Profile Name</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            placeholder="Name"
            v-model="form.name"
            :error-messages="form.$getError('name')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-text-field>

          <label class="text-field-label">Description</label>
          <v-textarea
            flat
            solo
            required
            class="mt-2"
            placeholder="description"
            v-model="form.description"
            :error-messages="form.$getError('description')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-textarea>

          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Building</label>
          <SelectMultipleBuildings
            flat
            solo
            required
            class="mt-2"
            v-model="form.building_id"
            :pre-select="!isUpdate"
            :error-messages="form.$getError('building_id')"
            :loading="form.$busy"
            :disabled="form.$busy"
          />
          <v-checkbox
            v-model="form.is_active"
            hide-details="auto"
            label="Set as active"
            class="mt-0"
          ></v-checkbox>
          <v-checkbox
            v-model="form.is_kiosk"
            hide-details="auto"
            label="Kiosk user"
            class="mt-0"
          ></v-checkbox>
          <div class="mt-4">
            <v-btn
              type="submit"
              color="primary"
              class="mr-4 px-6"
              height="40px"
              width="100%"
              :loading="form.$busy"
              >{{ buttonLabel }}</v-btn
            >
          </div>
        </v-col>
        <v-col cols="12" md="1"></v-col>
        <v-col cols="12" md="6">
          <h3 class="pb-5 primary--text">Feature Options</h3>

          <v-row>
            <v-col cols="12" md="6">
              <label class="text-field-label">Feature For You</label>
              <v-select
                v-model="form.featured"
                :items="itemsOptions"
                item-text="text"
                item-value="value"
                flat
                solo
              />
              <label class="text-field-label">Perks</label>
              <v-select
                v-model="form.perks"
                :items="itemsOptions"
                item-text="text"
                item-value="value"
                flat
                solo
              />
              <label class="text-field-label">News</label>
              <v-select
                v-model="form.news"
                :items="itemsOptionsFull"
                item-text="text"
                item-value="value"
                flat
                solo
              />
              <label class="text-field-label">Bookings</label>
              <v-select
                v-model="form.bookings"
                :items="itemsOptionsFull"
                item-text="text"
                item-value="value"
                flat
                solo
              />
              <label class="text-field-label">Events</label>
              <v-select
                v-model="form.events"
                :items="itemsOptionsFull"
                item-text="text"
                item-value="value"
                flat
                solo
              />
              <label class="text-field-label">Maintenance</label>
              <v-select
                v-model="form.maintenance"
                :items="itemsOptions"
                item-text="text"
                item-value="value"
                flat
                solo
              />
              <label class="text-field-label">FAQs</label>
              <v-select
                v-model="form.faqs"
                :items="itemsOptions"
                item-text="text"
                item-value="value"
                flat
                solo
              />
            </v-col>
            <v-col cols="12" md="6">
              <label class="text-field-label">Contact Directory</label>
              <v-select
                v-model="form.contact_directory"
                :items="itemsOptions"
                flat
                solo
              />
              <!-- <label class="text-field-label">Maps</label>
              <v-select
                v-model="form.map"
                :items="itemsOptions"
                item-text="text"
                item-value="value"
                flat
                solo
              /> -->
              <label class="text-field-label">Gallery</label>
              <v-select
                v-model="form.gallery"
                :items="itemsOptions"
                item-text="text"
                item-value="value"
                flat
                solo
              />
              <label class="text-field-label">Document</label>
              <v-select
                v-model="form.document"
                :items="itemsOptions"
                item-text="text"
                item-value="value"
                flat
                solo
              />
              <label class="text-field-label">Hub</label>
              <v-select
                v-model="form.hub"
                :items="itemsOptions"
                item-text="text"
                item-value="value"
                flat
                solo
              />
              <label class="text-field-label">Resident Directory</label>
              <v-select
                v-model="form.resident_directory"
                :items="itemsOptions"
                item-text="text"
                item-value="value"
                flat
                solo
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import SelectMultipleBuildings from '@/components/fields/SelectMultipleBuildings'

export default {
  components: { SelectMultipleBuildings },
  mixins: [SnackbarMixin, ErrorHandlerMixin],

  props: {
    profile: {
      type: Object,
      default: () => {
        return null
      },
    },
  },

  data() {
    return {
      form: new Form({
        name: '',
        description: '',
        building_id: [],
        featured: 'full_access',
        perks: 'full_access',
        news: 'full_access',
        bookings: 'full_access',
        events: 'full_access',
        maintenance: 'full_access',
        faqs: 'full_access',
        contact_directory: 'full_access',
        gallery: 'full_access',
        document: 'full_access',
        map: 'full_access',
        hub: 'full_access',
        resident_directory: 'full_access',
        is_active: false,
        is_kiosk: false,
      }),
      itemsOptionsFull: [
        {
          text: 'Full Access',
          value: 'full_access',
        },
        {
          text: 'No Access',
          value: 'no_access',
        },
        {
          text: 'View Only',
          value: 'read_only',
        },
      ],
      itemsOptions: [
        {
          text: 'Full Access',
          value: 'full_access',
        },
        {
          text: 'No Access',
          value: 'no_access',
        },
      ],
    }
  },

  computed: {
    ...mapState({
      createdProfile: (state) => state.profile.profileDetails,
      auth: (state) => state.auth.user,
    }),

    header() {
      return this.isUpdate ? 'Regular User' : 'Create Feature Profile'
    },

    buttonLabel() {
      return this.isUpdate ? 'Update' : 'Create'
    },

    submitApi() {
      return this.isUpdate ? this.updateProfile : this.createProfile
    },

    isUpdate() {
      return !!this.profile
    },
  },

  watch: {
    profile(newValue, oldValue) {
      this.initForm()
    },
  },

  methods: {
    ...mapActions({
      createProfile: 'profile/createProfile',
      updateProfile: 'profile/updateProfile',
    }),

    initForm() {
      if (this.profile) {
        this.form.name = this.profile.name
        this.form.building_id = this.profile.buildings.map((item) => item.id)
        this.form.description = this.profile.description
        this.form.featured = this.profile.featured
        this.form.perks = this.profile.perks
        this.form.news = this.profile.news
        this.form.bookings = this.profile.bookings
        this.form.events = this.profile.events
        this.form.maintenance = this.profile.maintenance
        this.form.faqs = this.profile.faqs
        this.form.contact_directory = this.profile.contact_directory
        this.form.gallery = this.profile.gallery
        this.form.document = this.profile.document
        this.form.map = this.profile.map
        this.form.hub = this.profile.hub
        this.form.resident_directory = this.profile.resident_directory
        this.form.is_active = this.profile.is_active
        this.form.is_kiosk = this.profile.is_kiosk
      }
    },

    async submit() {
      if (this.form.$busy || !this.validate()) return

      this.form.$busy = true
      this.form.$clearError()

      let forUpdate = this.isUpdate
      await this.submitApi(this.getFormData())
        .then(() => {
          if (forUpdate) {
            this.form.$busy = false
            this.showSnackbar('Profile details successfully updated!')
          } else {
            this.showSnackbar('Profile successfully created!')
            this.$router.push({
              name: 'feature-profile',
            })
          }
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    validate() {
      this.form.$clearErrors()

      if (this.isUpdate) return true

      return !this.form.$hasErrors()
    },

    getFormData() {
      let form = this.form.$data()

      if (this.isUpdate) {
        form.id = this.profile.id
        return form
      } else {
        const formData = new FormData()
        for (var field in form) {
          switch (field) {
            /* ...append normally everything else */
            default:
              formData.append(field, form[field])
              break
          }
        }
        formData.append('owner_id', this.auth.id)
        return formData
      }
    },
  },
}
</script>
