<template>
  <v-autocomplete
    ref="autocomplete"
    class="select-team"
    :chips="!!value && chip"
    required
    v-bind="$attrs"
    :items="validItems"
    :item-value="valueColumn"
    :placeholder="placeholder"
    :item-text="textColumn"
    :value="value"
    :loading="loading || fetching"
    :disabled="disabled || fetching"
    @input="onChange"
  ></v-autocomplete>
</template>
<script>
/**
 * ==================================================================================
 * Select Team
 * ==================================================================================
 **/
import { mapActions } from 'vuex'

export default {
  props: {
    value: {
      type: [String, Number],
      default: null,
    },

    items: {
      type: Array,
      default: () => [],
      validator: (value) => Array.isArray(value),
    },

    placeholder: {
      type: String,
      default: 'Select user',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    valueColumn: {
      type: String,
      default: 'id',
    },

    textColumn: {
      type: String,
      default: 'full_name',
    },

    preSelect: {
      type: Boolean,
      default: false,
    },

    preFetch: {
      type: Boolean,
      default: true,
    },

    buildingId: {
      type: [String, Number],
      default: null,
    },

    chip: {
      type: Boolean,
      default: true,
    },
  },

  created() {
    if (this.preFetch) {
      this.fetch()
    }
  },

  data() {
    return {
      fetching: false,
      teams: [],
    }
  },

  computed: {
    validItems() {
      let items = [
        ...this.items,
        ...(this.teams && this.teams.length ? this.teams : []),
      ]

      return !this.viaIndex
        ? items
        : items.map((item, i) => {
            return {
              index: i,
              ...item,
            }
          })
    },

    viaIndex() {
      return this.valueColumn.toLowerCase() === 'index'
    },
  },

  watch: {
    buildingId(newValue, oldValue) {
      this.fetch()
    },
  },

  methods: {
    ...mapActions({
      getTeams: 'team/getTeams',
    }),

    async fetch() {
      if (this.fetching) return
      this.fetching = true
      const params = { paginate: false }

      if (this.buildingId) {
        params.buildingId = this.buildingId
      }
      try {
        const data = await this.getTeams(params)
        this.teams = data

        this.$emit('populated', this.teams)
      } catch (error) {
        console.error('Error fetching teams:', error)
      } finally {
        this.fetching = false
      }
    },

    onChange(value) {
      this.$emit('input', value)
      this.$refs.autocomplete.blur()
    },
  },
}
</script>
